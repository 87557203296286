import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import(/* webpackChunkName: "upload" */ '../views/UploadView.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/CategoriesView.vue')
  },{
    path: '/parameters',
    name: 'parameters',
    component: () => import(/* webpackChunkName: "parameters" */ '../views/ParametersView.vue')
  },{
    path: '/colors',
    name: 'colors',
    component: () => import(/* webpackChunkName: "colors" */ '../views/ColorsView.vue')
  },{
    path: '/logs',
    name: 'logs',
    component: () => import(/* webpackChunkName: "logs" */ '../views/LogsView.vue')
  },{
    path: '/description',
    name: 'description',
    component: () => import(/* webpackChunkName: "description" */ '../views/DescriptionView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

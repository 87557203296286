<template>
  <v-card
      variant="outlined"
      class="ma-5">
    <v-card-text>
      <v-row>
        <v-col>
          <router-link
              v-for="(tab,index) in tabs" :key="index"
              :to="tab.route"
              v-slot="{ href, navigate, isActive }"
              class="mr-3"
          >
            <v-btn :active="isActive" :href="href" @click="navigate">
              {{ tab.name }}
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <router-view/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>
export default {
  name: "DashboardView",
  data: function () {
    return {
      "tab": null,
      "tabs": [
        {
          "name": "Главная",
          "route": "/",
        },
        {
          "name": "Загрузка файлов",
          "route": "upload",
        },
        {
          "name": "Категории",
          "route": "categories",
        },
        {
          "name": "Характеристики",
          "route": "parameters",
        },
        {
          "name": "Цвета",
          "route": "colors",
        },
        {
          "name": "Логи",
          "route": "logs",
        },
        {
          "name": "Описание",
          "route": "description",
        },
      ],
    };
  },
}
</script>